import React from "react"
import { Link } from "gatsby"


export default function MenuTwo({ items, active }) {
  return (
    <>
      <div id="block-menu-menu-level2" class="block block-menu last region-even odd region-count-2 count-3">
        <div className="content">
          <ul className="menu">
            {items.map((item, index) => (
              <li className={active === item ? `leaf active-trail` : 'leaf'}>
                <Link to={item} title="" activeClassName="active" partiallyActive={true}>
                  {item.replace(/.*\//mg, "").replace(/-/g, " ")}
                </Link>
              </li>
            ))}

          </ul>
        </div>
      </div>




    </>
  )
}