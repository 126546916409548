import React from "react"
import { Link } from "gatsby"


export default function MenuOne({ children, active }) {
  return (
    <>
      <div id="block-menu-menu-level1" className="block block-menu first last region-odd even region-count-1 count-2">
        <div className="content">
          <ul className="menu">
            <li className={ active === '/' ? `leaf active-trail` : 'leaf' }><Link to="/" title="Home" activeClassName="active">home</Link></li>
            <li className={ active === 'about-us' ? `leaf active-trail` : 'leaf' }><Link to="/about-us" title="" activeClassName="active" partiallyActive={true}>about us</Link></li>
            <li className={ active === 'what-we-do' ? `leaf active-trail` : 'leaf' }><Link to="/what-we-do" title="" activeClassName="active" partiallyActive={true}>what we do</Link></li>
            <li className={ active === 'our-work' ? `leaf active-trail` : 'leaf' }><Link to="/our-work" title="" activeClassName="active" partiallyActive={true}>our work</Link></li>
            <li className={ active === 'contact-us' ? `leaf active-trail` : 'leaf' }><Link to="/contact-us" title="Contact us" activeClassName="active" partiallyActive={true}>contact</Link></li>
          </ul>
        </div>
      </div>
    </>
  )
}