// import { withPrefix } from "gatsby"
import React from "react"
// import { Link } from "gatsby"
// import Img from "gatsby-image"

export default function Content({ children }) {
  return (
    <>
      <div id="content-area">
        <div id="node-3" class="node node-type-page build-mode-full clearfix">

          <div class="content">
            {children}
          </div>

        </div>
      </div>
    </>
  )
}
