import React from "react"
import { Link } from "gatsby"


export default function MenuFour({ items, active }) {
  return (
    <>
      <div id="block-menu-menu-level4-houses" class="block block-menu last region-even odd region-count-4 count-5">
        <div className="content">
          <ul className="menu">
            {items.map((item, index) => (
              <li className={active === item ? `leaf active-trail` : 'leaf'}>
                <Link to={item} title="" activeClassName="active">
                  {item.replace(/.*\//mg, "").replace(/-/g, " ")}
                </Link>
              </li>
            ))}

          </ul>
        </div>
      </div>

    </>
  )
}